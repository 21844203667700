<template>

  <div>

    <div v-if="editWeek && props.diary && allowEdit">  

      <WeekEditFazaHarSmoke 
        v-if="editWeek.faza === WeekFaza.Harvest" 
        :week="editWeek" 
        :diary="props.diary" 
        :is-loading-save="isLoadingSave"
        @updateweek="emits('updateWeek')" 
        @saveweek="handleSaveWeek($event)"
        @savesmoke="handleSaveWeekSmoke($event)"
        @canceleditweek="emits('cancelEditWeek', $event)" 
      />    

    </div>

    <div v-else-if="props.diary?.id && !allowEdit">
      <UiNoAccess />
    </div>
    
  </div>

</template>



<script setup lang="ts">

import type { Diary, Week } from '@/types/diary'
import { WeekFaza, createWeek } from '@/types/diary'

const emits = defineEmits([
  'cancelEditWeek',
  'updateWeek', 
  'removeWeek',
  'createWeekModal'
]);

interface Props {
  week?: Week | null,
  diary: Diary
}


const props = defineProps<Props>();

const route = useRoute();
const { $api, $ga, $helper, $head, $popup, $vartpl, $convert } = useNuxtApp();
const { t } = useI18n();
const linkWeek = ref<string>(route.params.week as string)
const linkWeekId = ref<number | null>(Number(route.params?.week));
const originalWeek = ref<Week | null>(props.week ?? useDiary().getWeek(props.diary, linkWeekId.value));
const isLoadingSave = ref(false);
const editWeek = ref<Week | null>(null);




const prepareData = () => {
  originalWeek.value = JSON.parse(JSON.stringify(originalWeek.value)) as Week;
  useWeek().convertUnitsForEdit(originalWeek.value);
  editWeek.value = JSON.parse(JSON.stringify(originalWeek.value)) as Week;
}


const handleSaveWeek = (week: Week) => {   
  
  if(!useWeek().validateWeek(props.diary.items_week, week, true)){
    return false;     
  }

  isLoadingSave.value = true;

  
  var saveWeek = JSON.parse(JSON.stringify(week)) as Week;


  useWeek().convertUnitsForSave(saveWeek);

  var form = saveWeek;    


  if(!form.id){   
    $api.postWeek(props.diary.id, form) 
    .then((res) => {
      emits('updateWeek', res.data.id);              
      isLoadingSave.value = false;
    })
    .catch(function (error) { 
      isLoadingSave.value = false;
      // $popup.error(t('create_week_view_error_failed'));
      $popup.error('Error');
    });  
  }else{
    $api.putWeek(props.diary.id, form.id, form) 
    .then((res) => {      
      isLoadingSave.value = false;
      console.log('saved week id');      
      console.log(res.data);      
      emits('updateWeek', res.data.id);    
    })
    .catch(function (error) { 
      isLoadingSave.value = false;
      // $popup.error(t('create_week_view_error_failed'));    
      $popup.error('Error');    
    });
  }
}

const allowEdit = computed(() => useAuth().isOwner(props.diary.item_user.id));
 
const handleSaveWeekSmoke = (week: Week) => {

}


onMounted(() => {  
  prepareData();
});


onUnmounted(() => {  
  editWeek.value = null;
});




</script>

<style scoped>
 
</style>
